import React, {Component} from 'react';
import '../scss/header.scss';
// import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
// import {routes} from '../routes';
class Header extends Component {
  constructor(props) {
    super(props);

     this.wrapperRef = React.createRef();
     this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      url: window.location.href,
      isOtherPage: true
    };
    this.setState = this.state.url;
    // console.log(this.state.isHomePage);
    // var isActive = window.location.pathname !== '/';
    // var className = isActive ? 'header-bg-2' : '';
    // console.log(className);
  }

  getUrls = (e) => {
    // console.log(e.target.pathname);

    const isHomePage = e.target.pathname === "/";

    // console.log(window.location)
    // if (this.state.isHomePage) {
    // this.setState = {
    //   isOtherPage : isHomePage
    // }
    this.menuToggle();
    var element = document.getElementById("header");
    if (isHomePage) {
      element.classList.remove("header2");
      element.classList.add("header1");
    } else {
      element.classList.remove("header1");
      element.classList.add("header2");
    }
    this.setState = { isOtherPage: isHomePage };
    // if (!this.state.isOtherPage){
    //   alert(this.state.isOtherPage);
    // }
    // }
    // console.log(this.state.isOtherPage);
    // console.log(this.state.isOtherPage);
  };
  menuToggle = (e) => {
    var menuClass = document.getElementById("menu");
    menuClass.classList.toggle("menuOpen");
    // console.log(e)
  };

  
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      
    var menuClass = document.getElementById("menu");
    menuClass.classList.remove("menuOpen");
    // console.log("hey")
    }
    // console.log(this.wrapperRef.current)
  }
  render() {
    // const {isOtherPage} = this.state;
    // console.log(isHomePage)
    return (
      <div>
        <header>
          <div id="header" className={"header header1"}>
            {/* {headerBg} */}
            <div className="topbar">
              <div className="logo">
                <a href="/#" className="animated bounceInDown">
                  <img src="/media/logo.png" alt="Logo" />
                </a>
              </div>
              <div className="menu-cover top-nav hiden-sec">
                <div className="menus">
                  <div className="menu-res">
                    {/* <h2><i class="fa fa-bars"></i></h2> */}
                    <input id="menu-toggle" type="checkbox" />
                    <label className="menu-button-container" htmlFor="menu-toggle">
                      <div onClick={this.menuToggle} className="menu-button"></div>
                    </label>
                  </div>
                  <ul
                    id="menu"
                    ref={this.wrapperRef}
                    className=" menu animated"
                  >
                    <li>
                      <NavLink
                        exact="true"
                        onClick={this.getUrls.bind(this)}
                        to="/"
                        activeclassname="active"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={this.getUrls.bind(this)}
                        to="/about"
                        activeclassname="active"
                        className=""
                      >
                        About
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={this.getUrls.bind(this)}
                        to="/careers"
                        activeclassname="active"
                      >
                        Career
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={this.getUrls.bind(this)}
                        to="/contact"
                        activeclassname="active"
                      >
                        Contact
                      </NavLink>
                    </li>
                    {/* <a className="login-b" href="/#">
                      Login
                    </a> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </header>
        {this.props.children}
      </div>
    );
  }
}


export default Header;
