import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import produce from "immer";
import axios from 'axios'
// import {Dialog} from '@material-ui/core';
import Button from "@material-ui/core/Button";
import ReCAPTCHA from "react-google-recaptcha";
// import {  Divider } from 'semantic-ui-react'
// import Divider from '@material-ui/core/Divider'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function isIndianMobileNumber(mobileNumber) {
  const regex = /^\+?([0-9]{2})\)?[-. ]?([0-9]{10})$/;
  return regex.test(mobileNumber);
}

export function isEmail(email) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

export function isAlphaNumeric(str) {
  return /^[a-zA-Z0-9 ]*$/.test(str);
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: { isDirty: false, value: "" },
      last_name: { isDirty: false, value: "" },
      organization: { isDirty: false, value: "" },
      email: { isDirty: false, value: "" },
      contact_number: { isDirty: false, value: "" },
      error: {
        first_name: { isEmpty: true, isRegEx: false },
        last_name: { isEmpty: true, isRegEx: false },
        organization: { isEmpty: true, isRegEx: false },
        email: { isEmpty: true, isEmail: false, isRequired: true },
        contact_number: { isEmpty: true, isPhone: false, isRequired: true },
      },
      thanku_message: false,
      hide_form: true,
    };

    this.verifyCallback = this.verifyCallback.bind(this);
  }

  // is form valid
  isFormValid = () => {
    if (
      this.state.first_name.isDirty &&
      this.state.last_name.isDirty &&
      this.state.organization.isDirty &&
      (this.state.email.isDirty || this.state.organization.isDirty)
//      this.verifyCallback()
    ) {
      if (
        this.state.error.first_name.isEmpty ||
        this.state.error.first_name.isRegEx ||
        this.state.error.last_name.isEmpty ||
        this.state.error.last_name.isRegEx ||
        this.state.error.organization.isEmpty ||
        this.state.error.organization.isRegEx ||
        (this.state.error.email.isRequired &&
          (this.state.error.email.isEmpty ||
            this.state.error.email.isEmail)) ||
        (this.state.error.contact_number.isRequired &&
          (this.state.error.contact_number.isEmpty ||
            this.state.error.contact_number.isPhone))
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  // handle the input change
  handleChange = (e) => {
    const incomingInputValue = e.target.value;
    const incomingInputName = e.target.name;

    switch (incomingInputName) {
      case "first_name":
        // first name is required
        // first name should support only alphanumeric
        if (incomingInputValue === "") {
          this.setState(
            produce(this.state, (draft) => {
              draft.first_name.value = incomingInputValue;
              draft.first_name.isDirty = true;
              draft.error.first_name.isEmpty = true;
              draft.error.first_name.isRegEx = false;
            })
          );
        } else if (!isAlphaNumeric(incomingInputValue)) {
          this.setState(
            produce(this.state, (draft) => {
              draft.first_name.value = incomingInputValue;
              draft.first_name.isDirty = true;
              draft.error.first_name.isEmpty = false;
              draft.error.first_name.isRegEx = true;
            })
          );
        } else {
          // no err
          this.setState(
            produce(this.state, (draft) => {
              draft.first_name.value = incomingInputValue;
              draft.first_name.isDirty = true;
              draft.error.first_name.isEmpty = false;
              draft.error.first_name.isRegEx = false;
            })
          );
        }
        break;

      case "last_name":
        // last name is required
        // last name should support only alphanumeric
        if (incomingInputValue === "") {
          this.setState(
            produce(this.state, (draft) => {
              draft.last_name.value = incomingInputValue;
              draft.last_name.isDirty = true;
              draft.error.last_name.isEmpty = true;
              draft.error.last_name.isRegEx = false;
            })
          );
        } else if (!isAlphaNumeric(incomingInputValue)) {
          this.setState(
            produce(this.state, (draft) => {
              draft.last_name.value = incomingInputValue;
              draft.last_name.isDirty = true;
              draft.error.last_name.isEmpty = false;
              draft.error.last_name.isRegEx = true;
            })
          );
        } else {
          // no err
          this.setState(
            produce(this.state, (draft) => {
              draft.last_name.value = incomingInputValue;
              draft.last_name.isDirty = true;
              draft.error.last_name.isEmpty = false;
              draft.error.last_name.isRegEx = false;
            })
          );
        }
        break;

      case "organization":
        //  company_name is required
        // company_name should support only alphanumeric
        if (incomingInputValue === "") {
          this.setState(
            produce(this.state, (draft) => {
              draft.organization.value = incomingInputValue;
              draft.organization.isDirty = true;
              draft.error.organization.isEmpty = true;
              draft.error.organization.isRegEx = false;
            })
          );
        } else if (!isAlphaNumeric(incomingInputValue)) {
          this.setState(
            produce(this.state, (draft) => {
              draft.organization.value = incomingInputValue;
              draft.organization.isDirty = true;
              draft.error.organization.isEmpty = false;
              draft.error.organization.isRegEx = true;
            })
          );
        } else {
          // no err
          this.setState(
            produce(this.state, (draft) => {
              draft.organization.value = incomingInputValue;
              draft.organization.isDirty = true;
              draft.error.organization.isEmpty = false;
              draft.error.organization.isRegEx = false;
            })
          );
        }
        break;

      case "email":
        if (incomingInputValue === "" && this.state.error.email.isRequired) {
          this.setState(
            produce(this.state, (draft) => {
              draft.email.value = incomingInputValue;
              draft.email.isDirty = true;
              draft.error.email.isEmpty = true;
              draft.error.email.isEmail = false;
            })
          );
        } else if (
          incomingInputValue === "" &&
          !this.state.error.email.isRequired
        ) {
          this.setState(
            produce(this.state, (draft) => {
              draft.email.value = incomingInputValue;
              draft.email.isDirty = true;
              draft.error.email.isEmpty = false;
              draft.error.email.isEmail = false;
            })
          );
        } else if (!isEmail(incomingInputValue)) {
          this.setState(
            produce(this.state, (draft) => {
              draft.email.value = incomingInputValue;
              draft.email.isDirty = true;
              draft.error.email.isEmpty = false;
              draft.error.email.isEmail = true;
            })
          );
        } else {
          // no err
          this.setState(
            produce(this.state, (draft) => {
              draft.email.value = incomingInputValue;
              draft.email.isDirty = true;
              draft.error.email.isEmpty = false;
              draft.error.email.isEmail = false;
              draft.error.contact_number.isRequired = false;
              draft.error.contact_number.isEmpty = false;
              draft.error.contact_number.isPhone = false;
            })
          );
        }
        break;

      case "contact_number":
        if (
          incomingInputValue === "" &&
          this.state.error.contact_number.isRequired
        ) {
          this.setState(
            produce(this.state, (draft) => {
              draft.contact_number.value = incomingInputValue;
              draft.contact_number.isDirty = true;
              draft.error.contact_number.isEmpty = true;
              draft.error.contact_number.isPhone = false;
            })
          );
        } else if (
          incomingInputValue === "" &&
          !this.state.error.contact_number.isRequired
        ) {
          this.setState(
            produce(this.state, (draft) => {
              draft.contact_number.value = incomingInputValue;
              draft.contact_number.isDirty = true;
              draft.error.contact_number.isEmpty = false;
              draft.error.contact_number.isPhone = false;
            })
          );
        } else if (!isIndianMobileNumber(incomingInputValue)) {
          this.setState(
            produce(this.state, (draft) => {
              draft.contact_number.value = incomingInputValue;
              draft.contact_number.isDirty = true;
              draft.error.contact_number.isEmpty = false;
              draft.error.contact_number.isPhone = true;
            })
          );
        } else {
          // no err
          this.setState(
            produce(this.state, (draft) => {
              draft.contact_number.value = incomingInputValue;
              draft.contact_number.isDirty = true;
              draft.error.contact_number.isEmpty = false;
              draft.error.contact_number.isPhone = false;
              draft.error.email.isRequired = false;
              draft.error.email.isEmpty = false;
              draft.error.email.isEmail = false;
            })
          );
        }
        break;

      default:
    }
  };

  // hideForm = () => {
  //   this.setState{(
  //     thanku_message:  this.state.thanku_message
  //   )}
  // };
  hideForm = () => {
		
		// 	this.state.hide_form = false
		
    // console.log(this.state.hide_form)
    this.setState({
			hide_form: !this.state.hide_form
		})


	}
  
  showThanku = () => {
    
    // setTimeout(() => {
    this.setState({
      thanku_message : true
    })
    // console.log(this.state.thanku_message)
    // console.log("working")
    // this.setState({
		// 	thanku_message: !this.state.thanku_message
		// }) 
    // }, 3000);
  }


  onSubmit = (e, inputType) => {
    let data = Object.keys(this.state).reduce((acc, item) => {
      if (item !== "error") {
        return {
          ...acc,
          [item]: this.state[item].value,
        };
      } else {
        return acc;
      }
    }, {});
    // console.log(this.hideForm)
    this.showThanku()
    this.hideForm()

    // console.log(data.recaptchaToken);
    data.recaptchaToken = this.state.recaptchaToken
    
    // console.log(data) 
    
    // console.log(this.showThanku)
    
    axios.post(process.env.REACT_APP_API_SERVER + 'schedule-demo/', data).then(
      function (response) {
        // handle success
        console.log(response);
        // setOpen(true);
      }
    )
    
    // console.log(open)

  };

  // recaptcha callback
  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!
//    const newState = produce(this.state, (draft) => {
//      draft[recaptchaToken] = recaptchaToken;
//      console.log(recaptchaToken)
//    })
    this.setState({
      recaptchaToken
    })
    // this.setState(prev => { return {...prev, [recaptchaToken]: recaptchaToken}});
    this.setState({
      recaptchaToken: recaptchaToken
    })
    // this.state.recaptchaToken = recaptchaToken
    // console.log(this.state.recaptchaToken)
//    console.log(newState);
//    this.setState(newState);
  }

  // return (
  //     <div className="form demoForm">
  //       <div className="form-heading">
  //         <h1>Send us a message</h1>
  //       </div>
  //     </div>

        
  //   );

  render() {
    // extract the values
    const {
      contact_number,
      first_name,
      last_name,
      email,
      organization,
      recaptchaToken,
      error,
    } = this.state;
    // this.showThanku()
    // this.hideForm()
    // const form_complete = this.state.form_complete;

    // console.log(this.showThanku())
      // console.log(this.hideForm())
      // {this.props.hide_form
        return (
        // {console.log(this.props)}
        <>
          {this.state.hide_form &&
        
          <div className="form demoForm">
            <div className="row">
              <div className="col-md-12 col-xl-6 col-lg-12 col-sm-12">
                <div className="form-heading">
                  <h1>Send us a message</h1>
                </div>
                <form onSubmit={this.onSubmit}>
                  <div className="textInput">
                    <TextField
                      onBlur={(e) => this.handleChange(e)}
                      required
                      className="textField"
                      name="first_name"
                      type="text"
                      placeholder="First Name"
                      variant="outlined"
                      error={
                        first_name.isDirty
                          ? error.first_name.isEmpty || error.first_name.isRegEx
                          : false
                      }
                      id="outlined-error-helper-text"
                      helperText={
                        first_name.isDirty
                          ? error.first_name.isEmpty
                            ? "First name is required"
                            : error.first_name.isRegEx
                            ? "Invalid first name"
                            : ""
                          : ""
                      }
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="textInput">
                    <TextField
                      onBlur={(e) => this.handleChange(e)}
                      required
                      className="textField"
                      name="last_name"
                      type="text"
                      placeholder="Last Name"
                      variant="outlined"
                      error={
                        last_name.isDirty
                          ? error.last_name.isEmpty || error.last_name.isRegEx
                          : false
                      }
                      id="outlined-error-helper-text"
                      helperText={
                        last_name.isDirty
                          ? error.last_name.isEmpty
                            ? "Last name is required"
                            : error.last_name.isRegEx
                            ? "Invalid last name"
                            : ""
                          : ""
                      }
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="textInput">
                    <TextField
                      onBlur={(e) => this.handleChange(e)}
                      required
                      className="textField"
                      name="organization"
                      type="text"
                      placeholder="Organization Name"
                      error={
                        organization.isDirty
                          ? error.organization.isEmpty || error.organization.isRegEx
                          : false
                      }
                      id="outlined-error-helper-text"
                      helperText={
                        organization.isDirty
                          ? error.organization.isEmpty
                            ? "Company name is required"
                            : error.organization.isRegEx
                            ? "Invalid Company name"
                            : ""
                          : ""
                      }
                      variant="outlined"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="textInput">
                    <TextField
                      onBlur={(e) => this.handleChange(e)}
                      className="textField"
                      name="email"
                      type="email"
                      placeholder="example@domain.com"
                      variant="outlined"
                      error={
                        email.isDirty
                          ? error.email.isEmpty || error.email.isEmail
                          : false
                      }
                      id="outlined-error-helper-text"
                      helperText={
                        email.isDirty
                          ? error.email.isEmpty
                            ? "Email id is required"
                            : error.email.isEmail
                            ? "Invalid Email id"
                            : ""
                          : ""
                      }
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="hr-sect">Or</div>

                  <div className="textInput">
                    <TextField
                      onBlur={(e) => this.handleChange(e)}
                      className="textField"
                      name="contact_number"
                      inputProps={{ maxLength: 13 }}
                      placeholder="Contact Number (Include country code eg: +91)"
                      variant="outlined"
                      error={
                        contact_number.isDirty
                          ? error.contact_number.isEmpty || error.contact_number.isPhone
                          : false
                      }
                      value={contact_number.value}
                      helperText={
                        contact_number.isDirty
                          ? error.contact_number.isEmpty
                            ? "Contact number is required"
                            : error.contact_number.isPhone
                            ? "Invalid Contact number"
                            : ""
                          : ""
                      }
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="textInput recaptchaStyle">
                    <ReCAPTCHA
                      className="textField"
                      required
                      ref={(r) => (this.captcha = r)}
                      sitekey="6Lc57sMUAAAAAEpCDQ-UFqwaOlgNIpLLbF1VNZ_2"
                      onChange={this.verifyCallback}
                    />
                  </div>

                  <div>
                    <Button
                      disabled={!this.isFormValid() || !recaptchaToken}
                      className="demoSubmitButton"
                      onClick={this.onSubmit}
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
              <div className="col-md-6">
                <div>

                <div className="formside-content">
                      <div className="form-heading">
                        <h1>Why reach us ?</h1>
                      </div>
										
										<p>AdProbe has incorporated multiple features to address the most critical pain points of the customers, making it the one-stop solution for all your ad-verification business needs.</p>
										<ul>
											<li>Seamless Integration<span></span></li>
											<li>Comprehensive Metrics<span></span></li>
											<li>Actionable Intelligence<span></span></li>
											<li>Proactive Monitoring <span></span></li>
										</ul>

										{/*<a href="/#">Learn more about us</a>*/}
									</div>
                </div>
              </div>
            </div>
          </div>
                }

          
          {
            this.state.thanku_message && 
            <div className="msgContent">
					       <div className="icon-cover">
						   <FontAwesomeIcon className="icon"   icon={faCheck}/>   
						   </div> 
						   <h1>Thank you</h1>
						   <p>One of our team member will reach out to you soon.</p>
						   </div>
          }
        </>
      )
    
    
    
  }
}

export default App;
