import React, {Component} from 'react';
import '../scss/career.scss';
import {jobsList} from './CareersData';
//import Modal from '@material-ui/core/Modal';
import { Dialog } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faClock, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import '../scss/ScheduleDemo.scss';
import { faCheck } from '@fortawesome/free-solid-svg-icons'
// import InputLabel from '@material-ui/core/InputLabel';
import ReCAPTCHA from "react-google-recaptcha";
// import '../scss/content.scss';

// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';

// import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import $ from "jquery";

class Career extends Component {
  // const [scroll, setScroll] = React.useState('paper');
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      jobsList,
      jobsData: jobsList,
      currentTab: 'all',
      error: {
        numLength: 13
    },
    contact_number: ''
    }
    this.verifyCallback = this.verifyCallback.bind(this);
  }
  
  toggleContent = (position_id) => {
    
    const { jobsData } = this.state;
    this.setState({
      jobsList: jobsData.filter(job => job.position_id === position_id),
      currentTab: position_id
    })
  }

  showContent = (position_id ) => {
    let job = jobsList.find(item => item.position_id === position_id);
     this.setState({
      open: !this.state.open,
      career: job
     })
     
  }

  handleClose = () => [
    this.setState({
      open: false
    })
  ]
  showAll = () => {
    const {jobsData} = this.state
    this.setState({
      jobsList: jobsData,
      currentTab: 'all'
    })
  }
  showDemoSlider = () => {
    this.setState({
    infoDialog: !this.state.infoDialog
  })
}
  handleChange = (e) => {
    const { error } = this.state;
    if(e.target.value && e.target.name === 'contact_number') {
        if(e.target.value.length === 1 && e.target.value.split('')[0] === '+'){
            this.setState({
                [e.target.name]: e.target.value
            })
        } else if(e.target.value.length === 1 && /^[0-9]+$/.test(e.target.value)) {
            this.setState({
                [e.target.name]: e.target.value,
                error: {
                    ...error,
                    numLength: 10
                }
            })
        }
         else if(/^[0-9]+$/.test(e.target.value.slice(1))) {
            
            this.setState({
                [e.target.name]: e.target.value
            })
        } else {
            return
        }
    } 
    else {
        
        this.setState({
            [e.target.name] : e.target.value,
            error: {
                ...error,
                numLength: 13
            }
        })
    }
    
}
verifyCallback(recaptchaToken) {
  // Here you will get the final recaptchaToken!!!  
  this.setState({
    recaptchaToken
  })
  //console.log(recaptchaToken, "<= your recaptcha token")
}


onSubmit = (e) => {
  const { email_id, error } = this.state;
  if(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email_id)) {
    this.handleClose();
   this.showDemoSlider();
  // eslint-disable-next-line array-callback-return
  let data = Object.keys(this.state).reduce((acc , item) => {
      if(item !== 'error') {
       return {
           ...acc,
           [item]: this.state[item]
       }
      }
      
  }, {});
  console.log(data);
  } else {
      this.setState({
          error: {
              ...error,
              email_id: true
          }
      })
  }
  
}
handleInputOnBlur = (inputType) => {
  if(!this.state[inputType]) {
      this.setState({
          error: {
              ...this.state.error,
              [inputType]: true
          }
      })
  }

}



  handleRadioChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  handleFileChange = (e) => {
    const {error} = this.state;
    var regex = new RegExp("(.*?).(pdf|docx|doc)$");
    if(!regex.test(e.target.value.toLowerCase())){
      this.setState({
        error: {
           ...error,
           Resume: true
        }
      })
    } else {
      this.setState({
        error: {
          ...error,
          Resume: false
        }
      })
    }


  }
    
    render() {
      // console.log(jobsList);
      const { open, career, jobsList , currentTab ,  contact_number, error ,recaptchaToken} = this.state;
      const { candidate_name  , email_id } = this.state;
                               
// console.log(jobsList)
      return (
        <div>
          <div className="dummy-header"></div>
          <section>
            <div className="comman-page-heading wow fadeIn">
              <h2>Career At Adprobe</h2>
            </div>
          </section>

          <section>
            <div className="career-sec-2-cover">
              <div className="career-sec-2">
                <div className="career-sec-2-heading wow fadeIn">
                  <h2>
                    Benefits to make employee Wellness, Professional & Personal
                    Growth
                  </h2>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div
                      className="benifits-box wow fadeInLeft"
                      data-wow-delay="0s"
                    >
                      <div className="benifits-box-icon">
                        <img src="/media/career-icon-1.svg" alt="careers" />
                      </div>
                      <h3>Great Team</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin a iaculis elit, eu congue eros. Sed rper lacus
                        velit, sit amet aliquam.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div
                      className="benifits-box wow fadeInDown"
                      data-wow-delay=".1s"
                    >
                      <div className="benifits-box-icon">
                        <img src="/media/career-icon-1.svg" alt="careers" />
                      </div>
                      <h3>Great Team</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin a iaculis elit, eu congue eros. Sed rper lacus
                        velit, sit amet aliquam.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div
                      className="benifits-box wow fadeInRight"
                      data-wow-delay=".2s"
                    >
                      <div className="benifits-box-icon">
                        <img src="/media/career-icon-1.svg" alt="careers" />
                      </div>
                      <h3>Great Team</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin a iaculis elit, eu congue eros. Sed rper lacus
                        velit, sit amet aliquam.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div
                      className="benifits-box wow fadeInLeft"
                      data-wow-delay=".1s"
                    >
                      <div className="benifits-box-icon">
                        <img src="/media/career-icon-1.svg" alt="careers" />
                      </div>
                      <h3>Great Team</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin a iaculis elit, eu congue eros. Sed rper lacus
                        velit, sit amet aliquam.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div
                      className="benifits-box wow fadeInUp"
                      data-wow-delay=".2s"
                    >
                      <div className="benifits-box-icon">
                        <img src="/media/career-icon-1.svg" alt="careers" />
                      </div>
                      <h3>Great Team</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin a iaculis elit, eu congue eros. Sed rper lacus
                        velit, sit amet aliquam.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div
                      className="benifits-box wow fadeInRight"
                      data-wow-delay=".3s"
                    >
                      <div className="benifits-box-icon">
                        <img src="/media/career-icon-1.svg" alt="careers" />
                      </div>
                      <h3>Great Team</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin a iaculis elit, eu congue eros. Sed rper lacus
                        velit, sit amet aliquam.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div>
            <section>
              <div className="jobs-cover">
                <div className="jobs-in">
                  <div className="jobs-header wow fadeIn">
                    <h2>Open Positions</h2>
                    <div className="jobs-filter">
                      <ul>
                        <li>
                          <a
                            href="/#"
                            className={currentTab === "all" ? "active" : ""}
                            onClick={() => this.showAll()}
                          >
                            All
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            href="/#"
                            className={
                              currentTab === "web_developer" ? "active" : ""
                            }
                            onClick={() => this.toggleContent("web_developer")}
                          >
                            Developer{" "}
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            href="/#"
                            className={
                              currentTab === "designer" ? "active" : ""
                            }
                            onClick={() => this.toggleContent("designer")}
                          >
                            Designer
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            href="/#"
                            className={
                              currentTab === "marketer" ? "active" : ""
                            }
                            onClick={() => this.toggleContent("marketer")}
                          >
                            Marketer
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            href="/#"
                            className={
                              currentTab === "researcher" ? "active" : ""
                            }
                            onClick={() => this.toggleContent("researcher")}
                          >
                            Researcher
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="jobs-list">
                    {/* <div className="job-box accordion">
                      <input type="checkbox" id="handle1" />
                      <h2 class="handle">
                        <label for="handle1">26A. Trappist Single <i>Full time</i></label>
                        <button>Apply now</button>
                      </h2>
                      <div class="content">
                        <p><strong>Overall Impression:</strong> A pale, bitter, highly attenuated and well carbonated Trappist ale, showing a fruity-spicy Trappist yeast character, a spicy-floral hop profile, and a soft, supportive grainy-sweet malt palate.</p>
                        <p><strong>History:</strong> While Trappist breweries have a tradition of brewing a lower-strength beer as a monk’s daily ration, the bitter, pale beer this style describes is a relatively modern invention reflecting current tastes. Westvleteren first brewed theirs in 1999, but replaced older lower-gravity products.</p>
                      </div>
                    </div> */}

                    {jobsList.map((data, index) => (
                      <div className="job-box accordion wow zoomIn" key={index}>
                        {/* <input type="checkbox" name={'collapse' + data.id} id={'handle' + data.id} /> */}
                        <h2 className="handle">
                          <label
                            onClick={() => this.showContent(data.position_id)}
                            htmlFor={"handle" + data.id}
                          >
                            <span>{data.position}</span>{" "}
                            <i>{data.positionType}</i> <p>{data.location}</p>
                          </label>
                          <button className="btn">Apply now</button>
                        </h2>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section>
            <div className="life-cover">
              <div className="life-in">
                <h2>Life at Adprobe </h2>
                <div className="row">
                  <div className="col-md-6 d-block d-md-none">
                    <div className="life-img-right">
                      <img src="/media/life.png" alt="life at adprobe" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="life-content-left">
                      <h3>Lorem Ipsum</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Quisque in ullamcorper metus. Nulla scelerisque sem eget
                        dictum pharetra. Integer fermentum arcu in leo faucibus
                        malesuada vitae at tellus. Sed in pharetra dui. Praesent
                        vel felis aliquet, pharetra tellus ut, ornare justo.
                      </p>
                      <p>
                        Nulla scelerisque sem eget dictum pharetra. Integer
                        fermentum arcu in leo faucibus malesuada vitae at
                        tellus. Sed in pharetra dui. Praesent vel felis aliquet,
                        pharetra tellus ut, ornare justo.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 d-none d-md-block">
                    <div className="life-img-right">
                      <img src="/media/life.png" alt="life at adprobe" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="life-img-right">
                      <img src="/media/life.png" alt="life at adprobe" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="life-content-left">
                      <h3>Lorem Ipsum</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Quisque in ullamcorper metus. Nulla scelerisque sem eget
                        dictum pharetra. Integer fermentum arcu in leo faucibus
                        malesuada vitae at tellus. Sed in pharetra dui. Praesent
                        vel felis aliquet, pharetra tellus ut, ornare justo.
                      </p>
                      <p>
                        Nulla scelerisque sem eget dictum pharetra. Integer
                        fermentum arcu in leo faucibus malesuada vitae at
                        tellus. Sed in pharetra dui. Praesent vel felis aliquet,
                        pharetra tellus ut, ornare justo.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 d-block d-md-none">
                    <div className="life-img-right">
                      <img src="/media/life.png" alt="life at adprobe" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="life-content-left">
                      <h3>Lorem Ipsum</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Quisque in ullamcorper metus. Nulla scelerisque sem eget
                        dictum pharetra. Integer fermentum arcu in leo faucibus
                        malesuada vitae at tellus. Sed in pharetra dui. Praesent
                        vel felis aliquet, pharetra tellus ut, ornare justo.
                      </p>
                      <p>
                        Nulla scelerisque sem eget dictum pharetra. Integer
                        fermentum arcu in leo faucibus malesuada vitae at
                        tellus. Sed in pharetra dui. Praesent vel felis aliquet,
                        pharetra tellus ut, ornare justo.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 d-none d-md-block">
                    <div className="life-img-right">
                      <img src="/media/life.png" alt="life at adprobe" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {open && (
            <Dialog
              open={open}
              onClose={this.handleClose}
              scroll="paper"
              className="dialogBox animate zoomIn"
            >
              {/* <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                  Modal title
                </DialogTitle> */}

              <div className="contentPosition">
                {/* <div className="dummyheight"></div> */}
                <div className="modal-header">
                  <h5 className="modal-title">{career.position}</h5>
                  <h5 className="model-info">
                    <span>
                      <FontAwesomeIcon
                        style={{
                          color: "#21366e",
                          fontSize: "24px",
                          margin: "0 10px",
                        }}
                        icon={faClock}
                      />
                      {career.positionType}
                    </span>{" "}
                    <span>
                      <FontAwesomeIcon
                        style={{
                          color: "#21366e",
                          fontSize: "24px",
                          margin: "0 10px",
                        }}
                        icon={faMapMarkerAlt}
                      />{" "}
                      {career.location}
                    </span>
                  </h5>

                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                {/* <div className="about">
                      <h5 className="headingText"><strong>Position:</strong></h5>
                      <h6 className="contentText">{career.position}</h6>
                      
                    </div> */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="about list-group">
                      {/* <h5 className="headingText"><strong>Type:</strong></h5> */}
                      {/* <h5 className=" pull-left"><span><FontAwesomeIcon style={{color: '#21366e' , fontSize: '24px',  margin: '0 10px'}} icon={faClock} />{career.positionType}</span> <span><FontAwesomeIcon style={{color: '#21366e' , fontSize: '24px', margin: '0 10px'}} icon={faMapMarkerAlt} /> {career.location}</span></h5> */}
                      {/* <h5 className="pull-left"><FontAwesomeIcon style={{color: '#21366e' , fontSize: '24px', margin: '0 10px'}} icon={faMapMarkerAlt} /> {career.location}</h5> */}
                    </div>
                    <div className="about list-group">
                      {/* <h5 className="headingText"><strong>Location:</strong></h5> */}
                      {/* <h5 className=""><FontAwesomeIcon style={{color: '#21366e' , fontSize: '24px', margin: '0 10px'}} icon={faMapMarkerAlt} /> {career.location}</h5> */}
                    </div>
                    <div className="about">
                      <h5 className="headingText">
                        <strong>About organisation:</strong>
                      </h5>
                      <br />
                      <h6 className="contentText">{career.aboutOrg}</h6>
                    </div>
                    <div className="applyNow">
                      {
                        <form onSubmit={this.onSubmit}>
                          <div
                            onBlur={() =>
                              this.handleInputOnBlur("candidate_name")
                            }
                            className="textInput"
                          >
                            <TextField
                              className="textField"
                              name="candidate_name"
                              type="string"
                              placeholder="Full Name"
                              variant="outlined"
                              size="30"
                              required="true"
                              error={error.candidate_name}
                              id="outlined-error-helper-text"
                              helperText={
                                error.candidate_name && "Field cannot be empty"
                              }
                              onChange={this.handleChange}
                            />
                          </div>

                          <div
                            onBlur={() => this.handleInputOnBlur("email_id")}
                            className="textInput"
                          >
                            <TextField
                              required
                              className="textField"
                              name="email_id"
                              type="email"
                              placeholder="example@domain.com"
                              variant="outlined"
                              error={error.email_id}
                              id="outlined-error-helper-text"
                              helperText={
                                error.email_id &&
                                "Enter a valid email and cannot be empty"
                              }
                              onChange={this.handleChange}
                            />
                          </div>

                          <div
                            onBlur={() =>
                              this.handleInputOnBlur("contact_number")
                            }
                            className="textInput"
                          >
                            <TextField
                              className="textField"
                              name="contact_number"
                              inputProps={{
                                maxLength: error.numLength,
                                // value: contact_number
                              }}
                              placeholder="Contact Number"
                              variant="outlined"
                              value={contact_number}
                              error={error.contact_number}
                              id="outlined-error-helper-text"
                              helperText={
                                error.contact_number && "Field cannot be empty"
                              }
                              onChange={this.handleChange}
                            />
                          </div>

                          <div>
                            <input
                              type="radio"
                              name="selectedRadio"
                              value="fresher"
                              onChange={this.handleChange}
                            />
                            <span>Fresher</span>
                            <input
                              type="radio"
                              name="selectedRadio"
                              value="experienced"
                              onChange={this.handleChange}
                            />
                            <span>Experienced</span>
                          </div>

                          <div>
                            {/*<div className="textInput">
                        <TextField 
                        className="textField"
                        name="college_name"
                        placeholder="College Name"
                        label='College Name'
                        variant='outlined'
                        required
                        onChange={this.handleChange}
                        />

                      </div>
                      <div className="textInput">
                      <FormControl variant="outlined" >
                        <InputLabel >
                          Year
                        </InputLabel>
                        <Select
                          native
                          
                          onChange={this.handleChange}
                          
                          
                        >
                          <option value="" />
                          <option value={1}>First</option>
                          <option value={2}>Second</option>
                          <option value={3}>Third</option>
                          <option value={4}>Fourth</option>
                        </Select>
                      </FormControl>

                      </div>*/}

                            <div className="fileButton">
                              <h6 style={{ float: "left" }}>
                                Resume(choose only .doc or .pdf file)
                              </h6>
                              <div>
                                <input
                                  className="fileInput"
                                  label="Resume"
                                  name="Resume"
                                  type="file"
                                  id="myFile"
                                  onChange={this.handleFileChange}
                                />
                              </div>
                              {error.Resume && (
                                <div className="error">FIle Not supported</div>
                              )}
                            </div>
                            <div className="textInput">
                              <ReCAPTCHA
                                className="textField"
                                ref={(r) => (this.captcha = r)}
                                sitekey="6Lc57sMUAAAAAEpCDQ-UFqwaOlgNIpLLbF1VNZ_2"
                                onChange={this.verifyCallback}
                              />
                            </div>
                          </div>

                          <div>
                            <Button
                              className="applySubmitButton btn"
                              onClick={this.onSubmit}
                              variant="contained"
                              color="primary"
                              disabled={
                                !candidate_name ||
                                !recaptchaToken ||
                                !contact_number ||
                                !email_id ||
                                error.Resume
                              }
                            >
                              Apply
                            </Button>
                          </div>
                        </form>
                      }
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* <ul class="about">
                            <h5 className="headingText"><strong>Position:</strong></h5>
                            <li class="list-group-item">{career.position}</li>
                        </ul> */}

                    <div className="about">
                      <h5 className="headingText">
                        <strong>Responsibilities:</strong>
                      </h5>
                      <h6 className="contentText">
                        <div className="contentText list-group list-group-flush">
                          {career.responsibilities.map((responsiblity) => (
                            <div className="list-group-item">
                              <FontAwesomeIcon
                                style={{ color: "#21366e", width: "8px" }}
                                icon={faCircle}
                              />
                              {`   ${responsiblity}`}
                            </div>
                          ))}
                        </div>
                      </h6>
                    </div>
                    <div className="about">
                      <h5 className="headingText">
                        <strong>Skills:</strong>
                      </h5>
                      <h6 className="contentText list-group list-group-flush">
                        {career.Skills.map((skill) => (
                          <div className="list-group-item">
                            <FontAwesomeIcon
                              style={{ color: "#21366e", width: "8px" }}
                              icon={faCircle}
                            />
                            {`    ${skill}`}
                          </div>
                        ))}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
          )}
          {this.state.infoDialog && (
            <Dialog
              open={this.state.infoDialog}
              onClose={this.showDemoSlider}
              className="demoDialog"
            >
              <div className="toggleMsg">
                <div className="msgContent">
                  <div>
                    <FontAwesomeIcon
                      className="icon"
                      style={{ textAlign: "center" }}
                      icon={faCheck}
                    />
                  </div>
                  <h1>Form Submitted</h1>
                </div>
              </div>
            </Dialog>
          )}
        </div>
      );
    }
  }
  
  
  export default Career;
