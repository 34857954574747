import React, {Component} from 'react';
import '../scss/footer.scss';
import { NavLink } from 'react-router-dom';
import axios from 'axios'
class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMessage: false
        }
    }    


    showMess = () => {
        this.setState({ showMessage : true })
    }

    onSubmit = (e, inputType) => {
        e.preventDefault()
            let data = [{ email: e.target[0].value}]
             this.showMess()
        axios.post(process.env.REACT_APP_API_SERVER + 'emailsubscription/', data).then(
            function (response) {
              // handle success
              console.log(response);
              // setOpen(true);
            }
        )

    }

  render() {
    // this.showMessage = false
    // console.log(this.showMessage)
    // const {
    //     email,
    // } = this.state
    return (
      <div>
        <footer>
              <div className="footer-cover">
                  <div className="footer">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="footer-menu social-media">
                                <h2>Subscribe Newsletter</h2>
                                <ul className='list'>
                                    <li />
                                    <li>
                                        <form className="form-group"  onSubmit={this.onSubmit}>
                                            {/* <input type="text" placeholder="Enter email address" name="email" /> */}
                                            <div className="input-group mb-3">
                                                <input type="email" name="email" className="form-control" placeholder="Enter email address" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                <div className="input-group-append">
                                                    <button type="submit" ><i className="fa fa-paper-plane" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                            
                                        </form>
                                        {
                                            this.state.showMessage && 
                                            <p>Thank you for Subscribe</p>

                                        }
                                    </li>
                                    <li>
                                        <ul className='list'>
                                            <li><a href="https://www.facebook.com/adprobe" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook-f"></i></a></li>
                                            <li><a href="https://mobile.twitter.com/ad_probe" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a></li>
                                            <li><a href="https://www.instagram.com/adprobe/" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>
                                            <li><a href="https://www.linkedin.com/company/adprobe/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <div className="footer-menu">
                                <h2>Site Map</h2>
                                <ul className="list">
                                <li><NavLink exact="true" to="/" activeclassname="active">Home</NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <div className="footer-menu">
                                <h2>Company</h2>
                                <ul className='list'>
                                    <li><a href="/terms-conditions">Terms & Conditions</a></li>
                                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-6">
                            <div className="footer-menu">
                                <h2>Resource</h2>
                                <ul className='list'>
                                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                                    <li><a href="/gdrp">GDPR Disclaimer</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <p className='copyrightstext'>&#169; 2023 IntelliApt Technologies Pvt Ltd All rights reserved</p>
                        </div>
                    </div>
                  </div>
              </div>
          </footer>

      </div>
    )
  }
}


export default Footer;
