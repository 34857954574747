import React, { Component } from "react";

// import ReactDOM from 'react-dom';
import {BrowserRouter as Router,Route,Routes} from "react-router-dom";
// import { routes } from "./routes";
import "./App.scss";
import Header from "./components/Header";
import Content from "./components/Content";
// import Functions from "./components/Functions";
// import Servicegrid from "./components/servicesGrid";
import Footer from "./components/footer";
import About from "./components/About";
import Career from "./components/Career";
import Contact from "./components/Contact";
import Gdrp from "./components/Gdrp";
import Privacy from "./components/privacy-policy";
import Terms from "./components/TermsAndConditions";
// import $ from "jquery";

class App extends Component {
  constructor(props) {
     super(props);
    this.state = {

      url: window.location.href,
    };
    this.getUrl=this.getUrl.bind(this)
    // if (nextProps.location !== this.props.location) {
    //   // navigated!
    // }
  }

  componentDidMount() {
    this.getUrl();
  }
  // componentWillReceiveProps() {
  //   this.getUrl();
  // }

  getUrl() {
    var url = window.location.href;
    // alert(url);

    return url;
  }

  render() {
    // var isActive = window.location.pathname === this.props.to;
    // var className = isActive ? 'active' : '';
    // console.log(window.location.pathname);

    return (
      <Router>
        <div className="App">
          <div className="App-header">
            <Header />

            {/* <Functions />
          <Servicegrid /> */}
            {/* <Content /> */}
            <Routes>
              <Route path="/" element={<Content />} />
              <Route path="/about" element={<About />} />
              <Route path="/careers" element={<Career />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/gdrp" element={<Gdrp />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/terms-conditions" element={<Terms />} />
            </Routes>
          
            <Footer />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
  